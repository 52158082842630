<template>
    <div class="fixed inset-0 z-50 flex justify-center items-center" v-if="loading">
        <div class="absolute inset-0 bg-black opacity-75"></div>
        <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="spinner" class="svg-inline--fa fa-spinner fa-w-16 fa-spin text-white h-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M304 48c0 26.51-21.49 48-48 48s-48-21.49-48-48 21.49-48 48-48 48 21.49 48 48zm-48 368c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48-21.49-48-48-48zm208-208c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48-21.49-48-48-48zM96 256c0-26.51-21.49-48-48-48S0 229.49 0 256s21.49 48 48 48 48-21.49 48-48zm12.922 99.078c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48c0-26.509-21.491-48-48-48zm294.156 0c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48c0-26.509-21.49-48-48-48zM108.922 60.922c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48-21.491-48-48-48z"></path></svg>
    </div>
    <Header :show-details="true"></Header>
    <div class="px-3 py-5 mt-4 bg-brandBlue text-white text-lg">
        <div class="flex justify-between items-center">
            <div>Member: {{ store.getters.user.first + ' ' + store.getters.user.last }}</div>
        </div>
        <div class="mt-3 flex flex-row items-center" v-if="spouse">
            <span class="flex-grow">Spouse: {{ spouse.first + ' ' + spouse.last }}</span>
        </div>
        <div class="mt-3 flex flex-row items-center" v-if="dependents.length > 0">
            <span class="flex-grow">Dependents: {{ dependents.filter(item => item.active === true).map(children => { return children.first }).join(', ') }}</span>
        </div>
    </div>
    <div class="w-full flex flex-row flex-grow" v-if="saved">
        <div class="bg-green-600 text-white px-3 py-3 font-bold text-center w-1/2 flex items-center justify-center"><span>Saved Successfully</span></div>
        <router-link to="/applications/forms" class="bg-primary text-white px-3 py-3 font-bold text-center w-1/2 flex items-center justify-center"><span>Start New Application</span></router-link>
    </div>

    <!-- Personal Details -->
    <div id="editPersonalDetails" class="w-full px-3 mt-6">
        <h2 class="text-xl font-bold text-text">Personal Details</h2>
        <h6 class="mt-4 text-lg font-bold text-text2">Member ID: {{ store.getters.user.member_id }}</h6>
        <h6 class="mt-4 text-lg font-bold text-text2">DOB: {{ store.getters.user.date_of_birth }}</h6>

        <div class="text-text2 text-lg">
            <!-- First Name -->
            <div class="mt-4 text-text font-bold required">First Name</div>
            <input type="text" name="first_name" id="first_name" :value="item.first" disabled readonly class="w-full px-4 py-2 border border-border rounded focus:outline-none">
            <!-- Last Name -->
            <div class="mt-4 text-text font-bold required">Last Name</div>
            <input type="text" name="last_name" id="last_name" :value="item.last" disabled readonly class="w-full px-4 py-2 border border-border rounded focus:outline-none">
            <!-- Email -->
            <div class="mt-4 text-text font-bold">Email</div>
            <input type="email" name="email" id="email" v-model="item.email" class="w-full px-4 py-2 border border-border rounded focus:outline-none">
            <!-- Mobile -->
            <div class="mt-4 text-text font-bold required">Mobile</div>
            <input type="email" name="mobile" id="mobile" v-model="item.mobile" class="w-full px-4 py-2 border border-border rounded focus:outline-none">

            <!-- Address -->
            <div class="flex flex-wrap justify-between text-text  font-bold mt-8">
                <div class="text-lg">Address</div>
                <div class=" flex items-center">
                    <input type="radio" name="address" id="home" value="home" v-model="mailing">
                    <label for="home">Home</label>
                </div>
                <div class=" flex items-center">
                    <input type="radio" name="address" id="mailing" value="mailing" v-model="mailing">
                    <label for="mailing">Mailing</label>
                </div>
            </div>
            <div id="homeAddress" v-show="mailing === 'home'">
                <div class="mt-4 text-text font-bold required">Address</div>
                <input type="text" name="address" id="address" v-model="item.address" class="w-full px-4 py-2 border border-border rounded focus:outline-none">
                <div class="mt-4 text-text font-bold required">Suburb</div>
                <div class="relative">
                    <input type="text" name="suburb" id="suburb" v-model="item.suburb" ref="autocomplete" class="w-full px-4 py-2 border border-border rounded focus:outline-none">
                </div>
                <div class="mt-4 text-text font-bold required">Postcode</div>
                <input type="text" name="postcode" id="postcode" v-model="item.postcode" class="w-full px-4 py-2 border border-border rounded focus:outline-none">
                <div class="mt-4 text-text font-bold required">State</div>
                <select id="state" name="state" class="w-full py-3 px-4 border border-border rounded focus:outline-none cursor-pointer" v-model="item.state">
                    <option value="Western Australia" class="cursor-pointer">Western Australia</option>
                    <option value="Queensland" class="cursor-pointer">Queensland</option>
                    <option value="New South Wales" class="cursor-pointer">New South Wales</option>
                    <option value="South Australia" class="cursor-pointer">South Australia</option>
                    <option value="Victoria" class="cursor-pointer">Victoria</option>
                    <option value="Tasmania" class="cursor-pointer">Tasmania</option>
                    <option value="Northern Territory" class="cursor-pointer">Northern Territory</option>
                    <option value="Australian Capital Territory" class="cursor-pointer">Australian Capital Territory</option>
                </select>
            </div>
            <div id="mailingAddress" v-show="mailing === 'mailing'">
                <div class="mt-4 text-text font-bold required">Mailing Address</div>
                <div class="relative">
                    <input type="text" name="postal_address" id="postal_address" v-model="item.postal_address" class="w-full px-4 py-2 border border-border rounded focus:outline-none">
                    <div>
                        <ul>
                            <li></li>
                        </ul>
                    </div>
                </div>
                <div class="mt-4 text-text font-bold required">Mailing Suburb</div>
                <input type="text" name="postal_suburb" id="postal_suburb" v-model="item.postal_suburb" ref="autocompletePostal" class="w-full px-4 py-2 border border-border rounded focus:outline-none">
                <div class="mt-4 text-text font-bold required">Mailing Postcode</div>
                <input type="text" name="postal_postcode" id="postal_postcode" v-model="item.postal_postcode" class="w-full px-4 py-2 border border-border rounded focus:outline-none">
                <div class="mt-4 text-text font-bold required">Mailing State</div>
                <select id="postal_state" name="postal_state" v-model="item.postal_state" class="w-full py-3 px-4 border border-border rounded focus:outline-none cursor-pointer">
                    <option value="Western Australia" class="cursor-pointer">Western Australia</option>
                    <option value="Queensland" class="cursor-pointer">Queensland</option>
                    <option value="New South Wales" class="cursor-pointer">New South Wales</option>
                    <option value="South Australia" class="cursor-pointer">South Australia</option>
                    <option value="Victoria" class="cursor-pointer">Victoria</option>
                    <option value="Tasmania" class="cursor-pointer">Tasmania</option>
                    <option value="Northern Territory" class="cursor-pointer">Northern Territory</option>
                    <option value="Australian Capital Territory" class="cursor-pointer">Australian Capital Territory</option>
                </select>
            </div>

            <!-- Bank Details -->
            <div class="mt-8">
                <h2 class="text-lg font-bold text-primary">Bank Details</h2>
                <div>
                    <div class="mt-4 text-text font-bold required">Account Name</div>
                    <input type="text" name="bank_account_name" id="bank_account_name" v-model="item.account_name" class="w-full px-4 py-2 border border-border rounded focus:outline-none" maxlength="32">
                    <div class="mt-4 text-text font-bold required">BSB</div>
                    <input type="text" name="bsb" id="bsb" v-model="item.account_bsb" class="w-full px-4 py-2 border border-border rounded focus:outline-none">
                    <div class="mt-4 text-text font-bold required">Account Number</div>
                    <input type="text" name="bank_account_number" id="bank_account_number" v-model="item.account_number" class="w-full px-4 py-2 border border-border rounded focus:outline-none">
                </div>
            </div>

            <!-- My Account -->
            <div class="flex flex-wrap justify-between text-text font-bold mt-8">
                <div class="text-lg">My Account</div>
                <div class=" flex items-center">
                    <input type="radio" name="is_my_account" id="yes" v-model="item.member_bank_account" value="1">
                    <label for="yes">Yes</label>
                </div>
                <div class=" flex items-center">
                    <input type="radio" name="is_my_account" id="no" v-model="item.member_bank_account" value="0">
                    <label for="no">No</label>
                </div>
            </div>
            <div class="mt-8">
                {{ disclaimer }}
            </div>
            <!-- Cards -->
            <div class="mt-8" v-if="item.cards && item.cards.length > 0">
                <h2 class="text-lg font-bold text-primary">Cards</h2>
                <div class="w-full">
                    <div v-for="(file, index) in item.cards" :key="index">
                        <img :src="s3URL + '/' + file.path " alt="" class="w-full" />
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="flex justify-between px-3 mt-5 items-center">
        <router-link to="/dashboard" class="block px-4 py-3 bg-gray-400 text-white rounded w-1/2 mr-2 text-center">Back</router-link>
        <button type="button" class="block px-4 py-3 bg-brandYellow text-white rounded w-1/2 text-center" @click="save">Save</button>
    </div>
</template>

<script>
import Header from '@/views/partials/Header'
import { computed, onBeforeMount, ref } from 'vue'
import { useStore } from 'vuex'
import axios from 'axios'
import { vue3Debounce } from 'vue-debounce'

export default {
    name: 'Profile',
    components: {
        Header
    },
    directives: {
        debounce: vue3Debounce({ lock: true })
    },
    setup () {
        const loading = ref(true)
        const store = useStore()
        const item = ref({})
        const mailing = ref('home')
        const saved = ref(false)
        const s3URL = process.env.VUE_APP_S3_URL
        const autocomplete = ref(null)
        const autocompletePostal = ref(null)
        const disclaimer = process.env.VUE_APP_DISCLAIMER

        const spouse = computed(() => {
            if (store.getters.user.relationships && store.getters.user.relationships.length > 0) {
                return store.getters.user.relationships.find(relationship => {
                    return relationship.type.name === 'Spouse'
                })
            } else {
                return null
            }
        })

        const dependents = computed(() => {
            if (store.getters.user.relationships && store.getters.user.relationships.length > 0) {
                return store.getters.user.relationships.filter(relationship => {
                    return relationship.type.name === 'Dependent'
                })
            } else {
                return []
            }
        })

        onBeforeMount(() => {
            store.dispatch('GET_ME').then(() => {
                item.value = store.getters.user
                loading.value = false
            })

            window.loader.load().then(() => {
                const autocompleteClientHome = new google.maps.places.Autocomplete(autocomplete.value, { types: ['locality'] })
                const autocompleteClientMailing = new google.maps.places.Autocomplete(autocompletePostal.value, { types: ['locality'] })

                autocompleteClientHome.addListener('place_changed', () => {
                    const place = autocompleteClientHome.getPlace()

                    place.address_components.forEach((component) => {
                        if (component.types.indexOf('locality') !== -1) {
                            item.value.suburb = component.short_name
                        } else if (component.types.indexOf('postal_code') !== -1) {
                            item.value.postcode = component.short_name
                        }
                    })
                })

                autocompleteClientMailing.addListener('place_changed', () => {
                    const place = autocompleteClientMailing.getPlace()

                    place.address_components.forEach((component) => {
                        if (component.types.indexOf('locality') !== -1) {
                            item.value.postal_suburb = component.short_name
                        } else if (component.types.indexOf('postal_code') !== -1) {
                            item.value.postal_postcode = component.short_name
                        }
                    })
                })
            })
        })

        function save () {
            loading.value = true

            axios.post(process.env.VUE_APP_API_URL + '/public/me', item.value).then(response => {
                if (response.data.success) {
                    saved.value = true
                    window.scrollTo(0, 0)
                    loading.value = false
                }
            })
        }

        // const debounce = (callback, wait) => {
        //     let timeoutId = null
        //     return (...args) => {
        //         window.clearTimeout(timeoutId)
        //         timeoutId = window.setTimeout(() => {
        //             callback.apply(null, args)
        //         }, wait)
        //     }
        // }

        const lookupPostcodes = (val, e) => {
            axios.get('https://digitalapi.auspost.com.au/postcode/search.json', {
                params: {
                    q: val
                }
            }).then((response) => {
                console.log(response)
            })
        }

        return {
            store,
            spouse,
            dependents,
            item,
            mailing,
            loading,
            save,
            saved,
            s3URL,
            lookupPostcodes,
            autocomplete,
            autocompletePostal,
            disclaimer
        }
    }
}
</script>

<style scoped>

</style>
