<template>
    <div class="fixed inset-0 z-50 flex justify-center items-center" v-if="loading">
        <div class="absolute inset-0 bg-black opacity-75"></div>
        <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="spinner" class="svg-inline--fa fa-spinner fa-w-16 fa-spin text-white h-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M304 48c0 26.51-21.49 48-48 48s-48-21.49-48-48 21.49-48 48-48 48 21.49 48 48zm-48 368c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48-21.49-48-48-48zm208-208c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48-21.49-48-48-48zM96 256c0-26.51-21.49-48-48-48S0 229.49 0 256s21.49 48 48 48 48-21.49 48-48zm12.922 99.078c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48c0-26.509-21.491-48-48-48zm294.156 0c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48c0-26.509-21.49-48-48-48zM108.922 60.922c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48-21.491-48-48-48z"></path></svg>
    </div>

    <Header :show-details="false" :show-menu="true"></Header>
    <div class="px-3 mt-4" v-if="store.getters.user.first">
        <div class="mt-3 text-lg text-brandBlue">Member: {{ store.getters.user.first + ' ' + store.getters.user.last }}</div>
        <div class="mt-3 text-lg text-brandBlue" v-if="spouse">Spouse: {{ spouse.first + ' ' + spouse.last }}</div>
        <div class="mt-3 text-lg text-brandBlue" v-if="dependents.length > 0">Dependents: {{ dependents.filter(item => item.active === true).map(children => { return children.first }).join(', ') }}</div>
    </div>

    <!-- Applications Stat -->
    <div class="bg-brandLightBlue rounded-lg text-text p-3 mt-4 m-3 text-lg" v-if="store.getters.user.applications">
        <!-- Pending -->
        <div class="flex justify-between">
            <div class="w-56 p-3 bg-white rounded-lg">Pending</div>
            <div class="w-16 p-3 mx-2 bg-white text-center rounded-lg">{{ store.getters.user.applications.pending }}</div>
            <router-link :to="'/applications/submissions?status=pending'" class="flex items-center justify-center">
                <img src="@/assets/images/icons/search.svg" alt="">
            </router-link>
        </div>
        <!-- Approved -->
        <div class="flex justify-between mt-3">
            <div class="w-56 p-3 bg-white rounded-lg">Approved</div>
            <div class="w-16 p-3 mx-2 bg-white text-center rounded-lg">{{ store.getters.user.applications.approved }}</div>
            <router-link :to="'/applications/submissions?status=approved'" class="flex items-center justify-center">
                <img src="@/assets/images/icons/search.svg" alt="">
            </router-link>
        </div>
        <!-- Incomplete -->
        <div class="flex justify-between mt-3">
            <div class="w-56 p-3 bg-white rounded-lg">Incomplete</div>
            <div class="w-16 p-3 mx-2 bg-white text-center rounded-lg">{{ store.getters.user.applications.incomplete }}</div>
            <router-link :to="'/applications/submissions?status=incomplete'" class="flex items-center justify-center">
                <img src="@/assets/images/icons/search.svg" alt="">
            </router-link>
        </div>
        <!-- Completed -->
        <div class="flex justify-between mt-3">
            <div class="w-56 p-3 bg-white rounded-lg">Completed</div>
            <div class="w-16 p-3 mx-2 bg-white text-center rounded-lg">{{ store.getters.user.applications.completed }}</div>
            <router-link :to="'/applications/submissions?status=completed'" class="flex items-center justify-center">
                <img src="@/assets/images/icons/search.svg" alt="">
            </router-link>
        </div>
    </div>

    <!-- NEW APPLICATION -->
    <div class="px-3">
        <router-link to="/applications/forms" class="block w-full py-6 mt-4 bg-brandYellow rounded-lg text-center text-lg text-white rounded">Start New Application</router-link>
    </div>

    <div class="flex justify-between items-center bg-primary px-3 py-5 mt-4 mx-3 mb-2 rounded-lg">
        <div class="text-white text-lg">Latest News</div>
    </div>
    <div class="px-3 py-5 cursor-pointer mx-3 rounded-lg" :class="{'bg-background2': index % 2 === 0}" v-for="(item, index) in news.slice(0, 3)" :key="index" @click="viewNews(item.id)">
        <div class="text-gray-600">Date: {{ dayjs(item.created_at).format('DD/MM/YYYY') }}</div>
        <div class="text-gray-600 mt-4 text-xl">{{ item.title }}</div>
    </div>
</template>

<script>
import Header from '@/views/partials/Header'
import { useStore } from 'vuex'
import { onBeforeMount, computed, ref } from 'vue'
import { useRouter } from 'vue-router'
import axios from 'axios'
import dayjs from 'dayjs'

export default {
    name: 'Dashboard',
    components: {
        Header
    },
    setup () {
        const store = useStore()
        const forms = ref([])
        const s3URL = process.env.VUE_APP_S3_URL
        const loading = ref(true)
        const router = useRouter()
        const news = ref([])

        const spouse = computed(() => {
            if (store.getters.user.relationships && store.getters.user.relationships.length > 0) {
                return store.getters.user.relationships.find(relationship => {
                    return relationship.type.name === 'Spouse'
                })
            } else {
                return null
            }
        })

        const dependents = computed(() => {
            if (store.getters.user.relationships && store.getters.user.relationships.length > 0) {
                return store.getters.user.relationships.filter(relationship => {
                    return relationship.type.name === 'Dependent'
                })
            } else {
                return []
            }
        })

        const viewNews = (id) => {
            router.push(`/news/${id}`)
        }

        onBeforeMount(() => {
            forms.value = store.getters.forms

            store.dispatch('GET_ME')
            store.dispatch('GET_FORMS').then(() => {
                forms.value = store.getters.forms
                loading.value = false
            })

            axios.get(process.env.VUE_APP_API_URL + '/public/news').then(response => {
                news.value = response.data.data
            })
        })

        return {
            store,
            spouse,
            dependents,
            forms,
            s3URL,
            loading,
            news,
            dayjs,
            viewNews
        }
    }
}
</script>

<style scoped>

</style>
