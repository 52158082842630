<template>
    <Header :show-details="true" :show-menu="false"></Header>
    <div class="px-2">
        <router-link to="/dashboard" class="block w-full py-6 mt-6 bg-brandLightBlue text-center text-lg text-white rounded">Go to my Dashboard</router-link>
        <router-link to="/applications/forms" class="block w-full py-6 mt-4 bg-brandLightBlue text-center text-lg text-white rounded">Start New Application</router-link>
        <router-link to="/applications/submissions" class="block w-full py-6 mt-4 bg-brandLightBlue text-center text-lg text-white rounded">Application Status</router-link>
        <router-link to="/profile" class="block w-full py-6 mt-4 bg-brandLightBlue text-center text-lg text-white rounded">My Profile</router-link>
        <!-- <router-link to="/offers" class="block w-full py-6 mt-4 bg-brandLightBlue text-center text-lg text-white rounded">CURRENT OFFERS</router-link> -->
        <router-link to="/news" class="block w-full py-6 mt-4 bg-brandLightBlue text-center text-lg text-white rounded">News</router-link>
    </div>
</template>

<script>
import Header from '@/views/partials/Header'

export default {
    name: 'Home',
    components: {
        Header
    },
    setup () {
        return {}
    }
}
</script>

<style scoped>

</style>
