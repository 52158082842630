<template>
    <Header :show-details="true"></Header>
    <div class="flex justify-between items-center bg-primary px-3 py-5 mt-4 mx-3 mb-2 rounded-lg">
        <div class="text-white text-lg">News</div>
    </div>
    <div class="px-3 py-5 cursor-pointer mx-3 rounded-lg" :class="{'bg-background2': index % 2 === 0}" v-for="(item, index) in news" :key="index" @click="viewNews(item.id)">
        <div class="text-gray-600">Date: {{ dayjs(item.created_at).format('DD/MM/YYYY') }}</div>
        <div class="text-gray-600 mt-4 text-xl">{{ item.title }}</div>
    </div>
    <div class="flex justify-between px-3 mt-4 items-center">
        <router-link to="/dashboard" class="block px-4 py-3 bg-brandYellow text-white rounded-lg w-full text-center">Back to Dashboard</router-link>
    </div>
</template>

<script>
import Header from '@/views/partials/Header'
import { onBeforeMount, ref } from 'vue'
import axios from 'axios'
import dayjs from 'dayjs'
import { useRouter } from 'vue-router'

export default {
    name: 'News',
    components: {
        Header
    },
    setup () {
        const router = useRouter()
        const news = ref([])

        const viewNews = (id) => {
            router.push(`/news/${id}`)
        }

        onBeforeMount(() => {
            axios.get(process.env.VUE_APP_API_URL + '/public/news').then(response => {
                news.value = response.data.data
            })
        })

        return {
            news,
            dayjs,
            viewNews
        }
    }
}
</script>

<style>
.news-content p {
    padding-bottom: 14px;
}

.news-content p:last-child {
    padding-bottom: 0;
}
</style>
