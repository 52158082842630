<template>
    <div class="fixed inset-0 z-50 flex justify-center items-center" v-if="emailSending">
        <div class="absolute inset-0 bg-black opacity-75"></div>
        <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="spinner" class="svg-inline--fa fa-spinner fa-w-16 fa-spin text-white h-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M304 48c0 26.51-21.49 48-48 48s-48-21.49-48-48 21.49-48 48-48 48 21.49 48 48zm-48 368c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48-21.49-48-48-48zm208-208c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48-21.49-48-48-48zM96 256c0-26.51-21.49-48-48-48S0 229.49 0 256s21.49 48 48 48 48-21.49 48-48zm12.922 99.078c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48c0-26.509-21.491-48-48-48zm294.156 0c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48c0-26.509-21.49-48-48-48zM108.922 60.922c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48-21.491-48-48-48z"></path></svg>
    </div>
    <div class="px-2">
        <!-- Logo -->
        <div id="laps-logo" class="text-primary flex flex-row justify-center">
            <img src="/img/custodian-community-logo.png" class="h-24">
        </div>
        <!-- Enter Mobile Number -->
        <div class="mt-5">
            <label class="text-brandBlue text-lg">LOG IN - Step 1</label>
            <input type="text" v-model="mobile" v-maska="'#### ### ###'" placeholder="Mobile Number" data-lpignore="true" class="w-full px-5 py-3 mt-3 rounded border border-border focus:outline-none">
            <button type="button" @click="sendCode" :disabled="codeSending" class="w-full py-3 mt-4 bg-brandYellow text-white text-center rounded-lg text-lg cursor-pointer flex flex-row justify-center items-center focus:outline-none">
                <svg v-if="codeSending" aria-hidden="true" focusable="false" data-prefix="far" data-icon="spinner" class="svg-inline--fa fa-spinner fa-w-16 fa-spin w-6 h-6 mr-4" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M296 48c0 22.091-17.909 40-40 40s-40-17.909-40-40 17.909-40 40-40 40 17.909 40 40zm-40 376c-22.091 0-40 17.909-40 40s17.909 40 40 40 40-17.909 40-40-17.909-40-40-40zm248-168c0-22.091-17.909-40-40-40s-40 17.909-40 40 17.909 40 40 40 40-17.909 40-40zm-416 0c0-22.091-17.909-40-40-40S8 233.909 8 256s17.909 40 40 40 40-17.909 40-40zm20.922-187.078c-22.091 0-40 17.909-40 40s17.909 40 40 40 40-17.909 40-40c0-22.092-17.909-40-40-40zm294.156 294.156c-22.091 0-40 17.909-40 40s17.909 40 40 40c22.092 0 40-17.909 40-40s-17.908-40-40-40zm-294.156 0c-22.091 0-40 17.909-40 40s17.909 40 40 40 40-17.909 40-40-17.909-40-40-40z"></path></svg>
                <span v-if="codeSending">Sending...</span>
                <span v-else-if="codeSent">Resend Code</span>
                <span v-else>Send Code to Phone</span>
            </button>
        </div>
        <!-- Enter Code -->
        <div class="mt-5" :class="{ 'opacity-50': !codeSent }">
            <label class="text-primary text-lg">LOG IN - Step 2</label>
            <input type="text" v-model="code" v-maska="'######'" :disabled="!codeSent" placeholder="Enter Code" data-lpignore="true" class="w-full px-5 py-3 mt-3 rounded border border-border focus:outline-none">
            <button type="button" @click="login" class="w-full py-3 mt-4 bg-brandYellow text-white text-center rounded-lg text-lg cursor-pointer flex flex-row justify-center items-center focus:outline-none">
                <svg v-if="loginLoading" aria-hidden="true" focusable="false" data-prefix="far" data-icon="spinner" class="svg-inline--fa fa-spinner fa-w-16 fa-spin w-6 h-6 mr-4" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M296 48c0 22.091-17.909 40-40 40s-40-17.909-40-40 17.909-40 40-40 40 17.909 40 40zm-40 376c-22.091 0-40 17.909-40 40s17.909 40 40 40 40-17.909 40-40-17.909-40-40-40zm248-168c0-22.091-17.909-40-40-40s-40 17.909-40 40 17.909 40 40 40 40-17.909 40-40zm-416 0c0-22.091-17.909-40-40-40S8 233.909 8 256s17.909 40 40 40 40-17.909 40-40zm20.922-187.078c-22.091 0-40 17.909-40 40s17.909 40 40 40 40-17.909 40-40c0-22.092-17.909-40-40-40zm294.156 294.156c-22.091 0-40 17.909-40 40s17.909 40 40 40c22.092 0 40-17.909 40-40s-17.908-40-40-40zm-294.156 0c-22.091 0-40 17.909-40 40s17.909 40 40 40 40-17.909 40-40-17.909-40-40-40z"></path></svg>
                <span v-if="loginLoading">Please wait...</span>
                <span v-else>Log In</span>
            </button>
        </div>
        <!-- Help Section -->
        <h2 class="mt-5 text-center text-xl text-brandBlue">Help</h2>
        <div class="flex flex-row justify-center">
            <div class="flex flex-row mt-4 gap-x-4">
                <!-- Call -->
                <a :href="`tel:${number}`" class="w-40 p-4 bg-brandLightBlue text-white cursor-pointer rounded-lg">
                    <div class="w-10 h-10 flex justify-center items-center rounded-full bg-white mx-auto">
                        <img src="@/assets/images/icons/Phone.svg" alt="Call">
                    </div>
                    <div class="mt-4 text-center text-lg">Call</div>
                </a>
                <!-- Email -->
                <a href="mailto:info@fasperth.com.au" class="w-40 p-4 bg-brandLightBlue text-white cursor-pointer rounded-lg">
                    <div class="w-10 h-10 flex justify-center items-center rounded-full bg-white mx-auto">
                        <img src="@/assets/images/icons/email.svg" alt="Email">
                    </div>
                    <div class="mt-4 text-center text-lg">Email</div>
                </a>
            </div>
        </div>
        <!-- New or Lost Mobile Number -->
        <div class="p-4 mt-8 bg-primary text-white">
            <div class="flex flex-wrap items-center">
                <div class="w-10 h-10 mr-4 flex justify-center items-center rounded-full bg-white">
                    <img src="@/assets/images/icons/Mobile.svg" alt="Call">
                </div>
                <div class="text-lg">New or Lost Mobile Number?</div>
            </div>
            <div class="mt-4 text-lg">Fill in the details below to update your record with a new number</div>
            <div class="mt-4 text-text2 clearfix flex flex-col">
                <input type="text" name="first_name" id="first_name" v-model="item.first" placeholder="First Name" class="w-full px-5 py-3 rounded-sm border border-border focus:outline-none">
                <input type="text" name="last_name" id="last_name" v-model="item.last" placeholder="Last Name" class="w-full px-5 py-3 mt-4 rounded-sm border border-border focus:outline-none">
                <input type="text" name="member_id" id="member_id" v-model="item.member_id" placeholder="Member ID (Optional)" class="w-full px-5 py-3 mt-4 rounded-sm border border-border focus:outline-none">
                <input type="tel" name="old_number" id="old_number" v-model="item.old" v-maska="'#### ### ###'" placeholder="Old Mobile Number" class="w-full px-5 py-3 mt-4 rounded-sm border border-border focus:outline-none">
                <input type="tel" name="new_number" id="new_number" v-model="item.new" v-maska="'#### ### ###'" placeholder="New Mobile Number" class="w-full px-5 py-3 mt-4 rounded-sm border border-border focus:outline-none">
                <button type="button" @click="sendEmail" class="float-right px-8 py-3 mt-4 bg-brandYellow text-white rounded-sm cursor-pointer focus:outline-none focus:shadow-none" :class="{'opacity-50': item.old === '' || item.new === '' || item.first === '' || item.last === ''}" :disabled="item.old === '' || item.new === '' || item.first === '' || item.last === ''">Send</button>
                <div class="bg-green-600 text-white px-3 py-3 text-center w-full flex items-center justify-center mt-2 font-normal" v-if="emailSent"><span>Email Sent - We will be in touch shortly</span></div>
            </div>
        </div>
    </div>
</template>

<script>
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { ref, onUnmounted, onDeactivated } from 'vue'
import axios from 'axios'

export default {
    name: 'Login',
    setup () {
        const store = useStore()
        const router = useRouter()

        const errorMessage = ref('')
        const mobile = ref('')
        const code = ref('')
        const codeSending = ref(false)
        const codeSent = ref(false)
        const loginLoading = ref(false)
        const emailSending = ref(false)
        const emailSent = ref(false)
        const number = process.env.VUE_APP_CONTACT_PHONE

        const item = ref({
            first: '',
            last: '',
            member_id: '',
            old: '',
            new: ''
        })

        function sendCode () {
            if (!codeSending.value) {
                codeSending.value = true

                axios.post(process.env.VUE_APP_API_URL + '/public/login-code', {
                    mobile: mobile.value
                }).then(response => {
                    if (response.data.success) {
                        codeSending.value = false
                        codeSent.value = true
                    }
                })
            }
        }

        function login () {
            if (mobile.value && code.value && !loginLoading.value) {
                loginLoading.value = true

                store.dispatch('AUTH_REQUEST', { mobile: mobile.value, code: code.value }).then((response) => {
                    loginLoading.value = false
                    router.push('home')
                }).catch(() => {
                    loginLoading.value = false
                    errorMessage.value = 'Unable to login, please try again.'
                })

                loginLoading.value = false
            }
        }

        function sendEmail () {
            emailSending.value = true
            emailSent.value = false

            axios.post(process.env.VUE_APP_API_URL + '/public/new-details', item.value).then(response => {
                if (response.data.success) {
                    emailSent.value = true
                    emailSending.value = false

                    item.value = {
                        first: '',
                        last: '',
                        member_id: '',
                        old: '',
                        new: ''
                    }
                }
            })
        }

        onUnmounted(() => {
            errorMessage.value = ''
            mobile.value = ''
            code.value = ''
            codeSending.value = false
            codeSent.value = false
            loginLoading.value = false
        })

        onDeactivated(() => {
            errorMessage.value = ''
            mobile.value = ''
            code.value = ''
            codeSending.value = false
            codeSent.value = false
            loginLoading.value = false
        })

        return {
            errorMessage,
            mobile,
            code,
            codeSending,
            codeSent,
            loginLoading,
            sendCode,
            login,
            item,
            sendEmail,
            emailSending,
            emailSent,
            number
        }
    }
}
</script>

<style scoped>

</style>
