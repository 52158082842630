<template>
    <Header :show-details="true"></Header>
    <div class="flex justify-between items-center bg-primary px-3 py-5 mt-4">
        <div class="text-white font-bold text-lg">{{ newsItem ? newsItem.title : '' }}</div>
    </div>
    <div class="px-3 py-5 cursor-pointer">
        <div class="mt-4 text-text news-content" v-html="newsItem ? newsItem.content : ''"></div>
    </div>
    <div class="flex justify-between px-3 mt-4 items-center">
        <router-link to="/news" class="block px-4 py-3 bg-brandYellow text-white rounded-lg w-full text-center">BACK TO NEWS</router-link>
    </div>
</template>

<script>
import Header from '@/views/partials/Header'
import { onBeforeMount, ref } from 'vue'
import axios from 'axios'
import dayjs from 'dayjs'

export default {
    name: 'NewsItem',
    components: {
        Header
    },
    props: {
        id: {
            required: true,
            type: String
        }
    },
    setup (props) {
        const newsItem = ref(null)

        onBeforeMount(() => {
            axios.get(process.env.VUE_APP_API_URL + '/public/news/' + props.id).then(response => {
                newsItem.value = response.data.data
            })
        })

        return {
            dayjs,
            newsItem
        }
    }
}
</script>

<style>
.news-content p {
    padding-bottom: 14px;
}

.news-content p:last-child {
    padding-bottom: 0;
}

.news-content img {
    display: inline-block!important;
}

.news-content figure.image {
    text-align: center;
}

.news-content a {
    text-decoration: underline;
    color: #005ca6;
}
.news-content ol {
    list-style: decimal;
    margin-left: 15px;
}

.news-content ol li {
    padding-left: 5px;
}
</style>
